import React from 'react'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import '../assets/scss/LostPassword.scss';

export const LostPassword = () => {
    const [password, setpassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [alert, setAlert] = useState({});
    const params = useParams();
    const { token } = params;
    const [isValid, setIsValid] = useState(false);
    const [messageIsValid, setMessageIsValid] = useState('Token no Valido');
    const [classIsValid, setClassIsValid] = useState('alert alert-danger')

    useEffect(() => {
        const checkToken = async () => {
            try {
                await axios(`http://localhost:4000/reset-password/${token}`);
                setIsValid(true);

            } catch (error) {
                console.log(error);
            }
        }
        checkToken();
    }, []);

    const handleSubmit = e => {
        e.preventDefault();

        if ([password, confirmPassword].includes('')) {
            setAlert({
                msg: 'Todos los campos son obligatorios',
                error: true
            })
            return;
        }
        if (password !== confirmPassword) {
            setAlert({
                msg: 'Los passwords no son iguales',
                error: true
            })
            return;
        }

        if (password.length < 6) {
            setAlert({
                msg: 'Debe contener al menos 6 caracteres',
                error: true
            })
            return;
        }
        setAlert({});

        //Create New Password
        const changePassword = async () => {
            try {
                const { data } = await axios.post(`http://localhost:4000/reset-password/${token}`, { password });
                console.log(data);
                setpassword('');
                setConfirmPassword('');
                setMessageIsValid('Password Cambiado Correctamente');
                setClassIsValid('alert alert-success');
                setIsValid(false);

            } catch (error) {
                console.log(error);
            }
        };
        changePassword();
    }
    return (
        <section className='lost-password-section'>
            <div className="container">
                <h1 className='mb-4'>Lost Password</h1>
                {alert.error ? <p className='alert alert-danger'>{alert.msg}</p> : null}
                {!isValid
                    ?
                    <p className={classIsValid}>{messageIsValid}</p>
                    :

                    <form
                        onSubmit={handleSubmit}
                    >

                        <div className="form-group mb-3">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={e => setpassword(e.target.value)}
                                pattern="(?=.*\d)(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ].*"
                                title="Debe tener al menos una mayúscula, una minúscula y un dígito"
                            />
                        </div>

                        <div className="form-group mb-5">
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                placeholder="Password"
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                pattern="(?=.*\d)(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ].*"
                                title="Debe tener al menos una mayúscula, una minúscula y un dígito"
                            />
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary"
                        >
                            Submit
                        </button>
                    </form>

                }


            </div>
        </section>
    )
}
